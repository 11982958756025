
export function checkImage(file: any, sizeOfM: number) {
    const isSupportImageType = checkImageType(file)
    const isLtnM = checkImageSize(file, sizeOfM)

    if (!isSupportImageType) {
        return {valid: false, message: '图片仅支持jpeg、png'}
    }
    if (!isLtnM) {
        return {valid: false, message: `图片大小不能超过${sizeOfM}M`}
    }
    return {valid: isSupportImageType && isLtnM, message: ""}
}

function checkImageSize(file: any, sizeOfM: number) {
    return file.size / 1024 / 1024 < sizeOfM
}

function checkImageType(file: any) {
    return file.type === 'image/jpeg'
        || file.type === 'image/png'
}

export function readImg(file: File) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        const reader = new FileReader()
        reader.onload = function(e) {
            if (this.result) {
                img.src = this.result.toString()
            } else {
                reject(this)
            }
        }
        reader.onerror = function(e) {
            reject(e)
        }
        reader.readAsDataURL(file)
        img.onload = function() {
            resolve(img)
        }
        img.onerror = function(e) {
            reject(e)
        }
    })
}

export function createCanvas(): any {
    let canvas = document.getElementById('canvas');
    if(!canvas) {
        const canvasTag = document.createElement('canvas');
        canvasTag.setAttribute('id','canvas');
        canvasTag.setAttribute('style','display:none;');
        document.body.appendChild(canvasTag);
        canvas = document.getElementById('canvas');
    }
    return canvas;
}

export function getScaleByWidth(maxWidth: number, width: number, height: number) {
    let scale: { width: any; height: any };
    let w = 0;
    let h = 0;
    if (width <= maxWidth) {
        scale = {
            width: width,
            height: height
        };
    } else {
        w = maxWidth;
        h = Math.ceil(maxWidth * height / width);
        scale = {
            width: w,
            height: h
        };
    }
    return scale;
}

export function getCompressedImage(image: HTMLImageElement, maxWidth: number, type?: string) {
    return new Promise((resolve, reject) => {
        const canvas = createCanvas();
        const ctx = canvas.getContext("2d");

        const imgScale = getScaleByWidth(maxWidth, image.width, image.height);
        canvas.width = imgScale.width;
        canvas.height = imgScale.height;
        ctx.clearRect(0, 0, imgScale.width, imgScale.height);
        ctx.drawImage(image, 0, 0, imgScale.width, imgScale.height);
        canvas.toBlob(function(blob: Blob | null) {
            resolve(blob)
        }, type || 'image/png', 0.75);
    })
}