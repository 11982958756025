import {CDN} from "@/config/cdn";
import {checkImage, getCompressedImage, readImg} from "@/utils/image";
import {ElMessage} from "element-plus/es";
import Vditor from "vditor";
import useNotes from "@/composables/useNotes";

export default function uesVditor(noteId: number, onContentChange: (value: string) => void, store: any = null) {

  const {uploadImage} = useNotes(store);

  let vditor: Vditor | null = null;

  const uploadHandler = async(file: any) => {
    const {valid, message} = checkImage(file[0], 5);
    if (!valid) {
      // vm.$message.error(message)
      ElMessage({
        showClose: true,
        message: message,
        type: 'error',
      })
      return;
    }
    const img: any = await readImg(file[0])
    const blob: any = await getCompressedImage(img, 999, file[0].type)
    const formData = new FormData()
    formData.append('file', blob, file[0].name)
    uploadImage(noteId, formData).then(function(response) {
      const imgMDStr = `![${file[0].name}](${response.data.location})`
      vditor?.insertValue(imgMDStr);
    }).catch(function (error) {
      console.log(error);
      const data = !error.response ? null : error.response.data;
      if (data != null) {
        ElMessage({
          showClose: true,
          message: error.response.data.error,
          type: 'error',
        });
      } else {
        ElMessage({
          showClose: true,
          message: '上传文件失败',
          type: 'error',
        });
      }
    })
  }

  const options: any = {
    height: 'calc(100vh - 122px)',
    cdn: CDN,
    preview: {
      theme: {
        path: `${CDN}/dist/css/content-theme`
      }
    },
    cache: {
      enable: false
    },
    value: ' ',
    toolbar: [
      'emoji',
      'headings',
      'bold',
      'italic',
      'strike',
      '|',
      'quote',
      'list',
      'ordered-list',
      'check',
      'outdent',
      'indent',
      'code',
      'inline-code',
      'table',
      'line',
      '|',
      'link',
      'upload',
      'insert-before',
      'insert-after',
      '|',
      'undo',
      'redo',
      'br',
      '|',
      'edit-mode',
      {
        name: "more",
        toolbar: [
          'outline',
          'preview',
          'export'
        ]
      }
    ],
    upload: {
      max: 1.5 * 1024 * 1024,
      multiple: false,
      handler: uploadHandler
    },
    input: onContentChange
  }

  vditor = new Vditor('vditor', options);
  return vditor;
}