
  import {computed, defineComponent, onMounted, toRefs, watch} from 'vue';
  import {ElMessage} from "element-plus/es";
  import {useRoute, useRouter} from "vue-router";
  import "vditor/dist/index.css"
  import {UNTITLED_REG_EXP} from "@/constants/note";
  import useMarkdownLinkCopy from '@/composables/useMarkdownLinkCopy';
  import {MARKDOWN_ENGINE_VDITOR} from "@/constants/note";
  import {FILE_TYPE_NOTE} from "@/constants/file-type";
  import { reactive } from 'vue';

  export default defineComponent({
    name: "EditTitleLine",
    emits: ['onTitleChange', 'onSubmitForm', 'onViewClick'],
    props: {
      browseMode: Boolean,
      id: Number,
      name: String
    },
    setup(props, {emit}) {
      interface EditTitleLineState {
        note: {
          name: string | null | undefined,
        },
        untitledNameCopy: string | null
      }

      const state: EditTitleLineState = reactive({
        note: {
          name: ''
        },
        untitledNameCopy: null
      });

      const router = useRouter();
      const {copyMarkdownLink} = useMarkdownLinkCopy();

      watch(() => props.name, async (newValue) => {
        state.note.name = newValue
      })

      const goBack = () => {
        if (props.browseMode) {
          emit('onViewClick')
        } else {
          router.back();
        }
      }

      const histories = () => {
          router.push({name: 'noteHistories', params: {noteId: props.id}});
      }

      const onNameChange = () => {
        emit("onTitleChange", state.note.name)
      }

      const submitForm = () => {
        emit("onSubmitForm")
      }

      const viewClick = () => {
        emit('onViewClick')
      }

      const onTitleInputFocus = () => {
        if (state.note.name && UNTITLED_REG_EXP.test(state.note.name)) {
          state.untitledNameCopy = state.note.name;
          state.note.name = "";
        }
      }

      const onTitleInputBlur = () => {
        if (state.note.name === "" && state.untitledNameCopy != null) {
          state.note.name = state.untitledNameCopy;
          state.untitledNameCopy = null;
        }
      }

      const copyNoteLink = () => {
        if (copyMarkdownLink({
          fileType: FILE_TYPE_NOTE,
          id: props.id,
          name: state.note.name,
          engine: MARKDOWN_ENGINE_VDITOR
        })) {
          ElMessage({
            showClose: true,
            message: '复制成功',
            type: 'success'
          });
        }
      }

      return {
        ...toRefs(state),
        goBack,
        histories,
        onNameChange,
        submitForm,
        viewClick,
        onTitleInputFocus,
        onTitleInputBlur,
        copyNoteLink,
      }
    }
  })
